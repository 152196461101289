"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//import 'bootstrap/js/dist/alert';
require("bootstrap/js/dist/button");
//import 'bootstrap/js/dist/carousel';
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/modal");
//import 'bootstrap/js/dist/tab';
require('./web/bootstrap');
require('./web/init');
