"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LazyLoad = /** @class */ (function () {
    function LazyLoad() {
    }
    LazyLoad.prototype.init = function () {
        this.initLazyLoad();
    };
    LazyLoad.prototype.initLazyLoad = function () {
        document.addEventListener('DOMContentLoaded', function () {
            var lazyloadImages;
            if ('IntersectionObserver' in window) {
                lazyloadImages = document.querySelectorAll('.js-lazy');
                var imageObserver_1 = new IntersectionObserver(function (entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            var image = entry.target;
                            if (image.dataset.src)
                                image.src = image.dataset.src;
                            image.classList.remove('js-lazy');
                            imageObserver_1.unobserve(image);
                        }
                    });
                });
                lazyloadImages.forEach(function (image) { return imageObserver_1.observe(image); });
            }
            else {
                var lazyloadThrottleTimeout_1;
                ;
                lazyloadImages = document.querySelectorAll('.js-lazy');
                var lazyload_1 = function () {
                    if (lazyloadThrottleTimeout_1)
                        clearTimeout(lazyloadThrottleTimeout_1);
                    lazyloadThrottleTimeout_1 = setTimeout(function () {
                        var scrollTop = window.pageYOffset;
                        if (lazyloadImages) {
                            lazyloadImages.forEach(function (image) {
                                if (image.offsetTop < window.innerHeight + scrollTop) {
                                    if (image.dataset.src)
                                        image.src = image.dataset.src;
                                    image.classList.remove('js-lazy');
                                }
                            });
                        }
                        if (!lazyloadImages || lazyloadImages.length == 0) {
                            document.removeEventListener('scroll', lazyload_1);
                            window.removeEventListener('resize', lazyload_1);
                            window.removeEventListener('orientationChange', lazyload_1);
                        }
                    }, 20);
                };
                document.addEventListener('scroll', lazyload_1);
                window.addEventListener('resize', lazyload_1);
                window.addEventListener('orientationChange', lazyload_1);
            }
        });
    };
    return LazyLoad;
}());
exports.default = new LazyLoad;
